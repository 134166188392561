import React, { useState, useEffect } from 'react';
import landersLogo from "../Img/Logo landersystem.com (1).png"
import iconoLoginBarra from "../Img/login-circle-line.png"
import flechaSeccionBarra from "../Img/arrow-down-s-line.png"
import iconoLanders from "../Img/Logo Set landersystem.com.png"
import "./NavBar.css"

const BarraNavegacion = () => {
    const [scrollPos, setScrollPos] = useState(0);
    const [visible, setVisible] = useState(true);
    const [isVisible, setIsVisible] = useState(false);

    const handleButtonClick = () => {
        setIsVisible(!isVisible);
    };

    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const actualizarWidth = () => {
        setWindowWidth(window.innerWidth);
    };

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    useEffect(() => {
        window.addEventListener('resize', actualizarWidth);
        return () => {
            window.removeEventListener('resize', actualizarWidth);
        };
    }, []);
    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.scrollY;
            setVisible(scrollPos > currentScrollPos || currentScrollPos < 10);
            setScrollPos(currentScrollPos);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [scrollPos]);

    const estiloBarraEstatica = {
        top: visible ? 0 : '-90px', // Oculta la barra moviéndola hacia arriba
    };

    const estiloBarra = {
        top: visible ? 0 : '-90px', // Oculta la barra moviéndola hacia arriba
    };

    const LogoStyle = {
        position: 'relative',
        opacity: isHovered ? '100%' : '0%',
        transition: 'opacity 0.5s ease-in-out',
    };

    const iconoStyle = {
        position: 'absolute',
        top: "13px",
        left: isHovered ? "8.5px" : "70px",
        opacity: isHovered ? "0%" : "100%",
        transition: "opacity 1s ease, left 0.2s ease-in-out"
    }

    return (
        <div className='NavBar' style={{ marginBottom: "35px" }}>
            {windowWidth > 767 && <div>
                <div className='barraEstatica' style={estiloBarraEstatica}></div>
                <div className='barraSticky' style={estiloBarra}>
                    <div className='barraSeccionIzq'>
                        <div className='seccionBarra'
                            onMouseOver={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}>
                            <img className='logoLanders' src={landersLogo}
                                style={LogoStyle}></img>
                            <img className='iconoLanders' src={iconoLanders}
                                style={iconoStyle}></img>
                        </div>
                    </div>
                    <div className='barraSeccionMedio'>
                        <div className='seccionBarra'  >
                            <p className='tituloSeccion'>
                                <a href='https://partners.landersteams.com/lista-de-servicios/'>Servicios</a>
                            </p>
                            <img className='flechaSeccionBarra' src={flechaSeccionBarra}></img>
                        </div>
                        <div className='seccionBarra'  >
                            <p className='tituloSeccion'>
                                <a href='https://partners.landersteams.com/nosotros/'>¿Quiénes somos?</a>
                            </p>
                            <img className='flechaSeccionBarra' src={flechaSeccionBarra}></img>
                        </div>
                        <div className='seccionBarra'  >
                            <p className='tituloSeccion'>
                                <a href='https://partners.landersteams.com/authentication/?step=1'>Únete</a>
                            </p>
                            <img className='flechaSeccionBarra' src={flechaSeccionBarra}></img>
                        </div>
                    </div>
                    <div className='barraSeccionDer'>
                        <div className='seccionBarra'  >
                            <p className='tituloSeccion'>
                                <a href='https://partners.landersteams.com/login'>Login</a>
                                <img className='iconoLoginBarra' src={iconoLoginBarra}></img>
                            </p>
                        </div>
                    </div>
                </div>
            </div>}
            {windowWidth < 767 && <div>

                <div className={`overlay ${isVisible ? 'show' : 'hide'}`}>
                    <div className='contenedorBarraVertical'>
                        <div className='seccionBarraVertical'  >
                            <a href='https://partners.landersteams.com/login'
                                className='tituloSeccionBarraVertical'>Login</a>
                            <p className='iconoSeccionBarraVertical'>&#62;</p>
                        </div>
                        <div className='seccionBarraVertical'>
                            <a href='https://partners.landersteams.com/lista-de-servicios/'
                                className='tituloSeccionBarraVertical'>Servicios</a>
                            <p className='iconoSeccionBarraVertical'>&#62;</p>
                        </div>
                        <div className='seccionBarraVertical'>
                            <a href='https://partners.landersteams.com/nosotros/'
                                className='tituloSeccionBarraVertical'>¿Quiénes somos?</a>
                            <p className='iconoSeccionBarraVertical'>&#62;</p>
                        </div>
                        <div className='seccionBarraVertical'>
                            <a href='https://partners.landersteams.com/authentication/?step=1'
                                className='tituloSeccionBarraVertical'>Únete</a>
                            <p className='iconoSeccionBarraVertical'>&#62;</p>
                        </div>

                    </div>
                </div>

                <div className='barraEstatica' style={estiloBarraEstatica}></div>
                <div className='barraSticky' style={estiloBarra}>
                    <div className='seccionBarra'>
                        <img className='logoLanders' src={landersLogo}></img>
                    </div>
                    <div className='seccionBarra'>
                        <div className='iconoMenu'
                            onClick={handleButtonClick}
                            style={{
                                position: "absolute",
                                top: "35%",
                                right: "15%",
                                width: "35px"
                            }}>
                            <div style={{
                                backgroundColor: "white",
                                width: "35px",
                                height: "5px"
                            }}>
                            </div>
                            <div style={{
                                backgroundColor: "black",
                                width: "35px",
                                height: "5px"
                            }}>
                            </div>
                            <div style={{
                                backgroundColor: "white",
                                width: "35px",
                                height: "5px"
                            }}>
                            </div>
                            <div style={{
                                backgroundColor: "black",
                                width: "35px",
                                height: "5px"
                            }}>
                            </div>
                            <div style={{
                                backgroundColor: "white",
                                width: "35px",
                                height: "5px"
                            }}>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </div>

    );
};

export default BarraNavegacion;