import React, { useState, useEffect } from 'react';
import './CarruselAuto.css';

const TuComponente = () => {
    const [indiceActual, setIndiceActual] = useState(0);
    const [transicionSalida, setTransicionSalida] = useState(false);
    const [indiceResaltado, setIndiceResaltado] = useState(null);

    const cambiarImagen = (indice) => {
        setTransicionSalida(true);

        setTimeout(() => {
            setIndiceActual(indice);
            setIndiceResaltado(indice);
            setTransicionSalida(false);
        }, 500);
    };

    const data = [
        { titulo: 'Marzo 2023', descripcion: 'Lanzamos convocatoria de partners 2024: Conoce cómo aplicar y los requisitos.' },
        { titulo: 'Diciembre 2023', descripcion: 'Landers Teams: Plataforma Latinoamericana de teletrabajo orientada a la aceleración de emprendimientos con inteligencia artificial.' },
        { titulo: 'Enero 2024', descripcion: 'Landers se asocia a la Cámara Latinoamericana de Comercio para participar en Eventos Empresariales 2024' },
    ];
    useEffect(() => {
        const intervalId = setInterval(() => {
            setIndiceActual((prevIndex) => (prevIndex + 1) % data.length);
        }, 4000); // Cambia el valor del intervalo según tus necesidades

        return () => clearInterval(intervalId);
    }, [data]);

    return (
        <div className="carruselAuto">
            <div className='divPrincipalCarruselAuto'>
                <div className='divTituloCarruselAuto'>
                    <h2 className='tituloCarruselAuto'>Landers News</h2>
                </div>
                <div className={`divFechaMensaje ${transicionSalida ? 'salida' : ''}`}>
                    <div className='contenedorDivFechaMensaje'>
                        <h3 className='fechaCarruselAuto'>{data[indiceActual].titulo}</h3>
                        <h2 className='mensajeCarruselAuto'>{data[indiceActual].descripcion}</h2>
                    </div>
                </div>
                <div className='controladorCarruselAuto'>
                    <div className="filaControl">
                        {data.map((dato, index) => (
                            <div
                                key={index}
                                className={`itemControl ${indiceResaltado === index ? 'resaltado' : ''}`}
                                onClick={() => cambiarImagen(index)}
                            >
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TuComponente;