import React, { useRef, useEffect, useState } from 'react';
import iconoFlechaExpandir from "../Img/arrow-right-s-line.png";

import "./FotoReduccion.css"

const MiComponente = () => {

    const divPrincipalRef = useRef(null);
    const divStickyRef = useRef(null);
    const [sticky, setSticky] = useState(false);
    const [scrollY, setScrollY] = useState(0);

    const [isVisible, setIsVisible] = useState(false);
    const [estaticoVisible, setEstaticoVisible] = useState(false)
    const secondDivRef = useRef(null);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const [windowHeight, setWindowHeight] = useState(window.innerHeight)
    const actualizarWidth = () => {
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight)
    };
    useEffect(() => {
        window.addEventListener('resize', actualizarWidth);
        return () => {
            window.removeEventListener('resize', actualizarWidth);
        };
    }, []);

    const handleScroll = () => {
        if (secondDivRef.current) {
            const rect = secondDivRef.current.getBoundingClientRect();
            const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;
            setIsVisible(isVisible);

            const estaticoVisible = rect.top < 0
            setEstaticoVisible(estaticoVisible)
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    useEffect(() => {

        const handleScroll = () => {
            const divPrincipal = divPrincipalRef.current;
            const divSticky = divStickyRef.current;

            if (divPrincipal && divSticky) {
                const divPrincipalRect = divPrincipal.getBoundingClientRect();
                const divStickyRect = divSticky.getBoundingClientRect();
                const newScrollY = window.scrollY;

                setScrollY(newScrollY);

                if (
                    divPrincipalRect.bottom >= window.innerHeight &&
                    divPrincipalRect.top <= 0
                ) {
                    setSticky(true);
                } else {
                    setSticky(false);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const calculateSize = () => {
        let initialScroll =
            windowWidth > 1559 && windowHeight < 800 ? 2600 :
            windowWidth > 1559 && windowHeight < 1000 ? 3000 : 
            windowWidth > 1559 && windowHeight < 1300 ? 3500 : 
            windowWidth > 1559 && windowHeight < 1600 ? 4100 : 
            windowWidth > 1559 && windowHeight < 2000 ? 4750 : 
                windowWidth > 1279 && windowHeight < 800 ? 2600 :
                windowWidth > 1279 && windowHeight < 1000 ? 3000 : 
                windowWidth > 1279 && windowHeight < 1300 ? 3500 : 
                windowWidth > 1279 && windowHeight < 1600 ? 4100 : 
                    windowWidth > 1023 && windowHeight < 750 ? 2600 : 
                    windowWidth > 1023 && windowHeight < 900 ? 2850 : 
                    windowWidth > 1023 && windowHeight < 1200 ? 3200 : 
                    windowWidth > 1023 && windowHeight < 1500 ? 4000 : 2500



        // Valor inicial de scrollY
        const minY = 0.8 * window.innerHeight; // 80% del alto de la ventana
        const maxY = window.innerHeight; // Alto máximo
        const minX = 0.50 * window.innerWidth; // 50% del ancho de la ventana
        const maxX = window.innerWidth; // Ancho máximo

        // Calcular la diferencia en scrollY desde el valor inicial
        const deltaY = Math.abs(scrollY - initialScroll);

        // Calcular la proporción de reducción o aumento
        const ratio = Math.min(1, deltaY / initialScroll);

        // Calcular el nuevo ancho y alto del divSticky
        let newWidth = maxX - (maxX - minX) * ratio;
        let newHeight = maxY - (maxY - minY) * ratio;

        

        return { width: `${newWidth}px`, height: `${newHeight}px` };
    };

    return (
        <div className='estiloDivPrincipalFotoReduccion'
            ref={divPrincipalRef}
        >
            <div className='estiloDivStickyFotoReduccion'
                ref={divStickyRef}
                style={{
                    ...calculateSize(),
                    position: sticky ? 'fixed' : 'absolute',
                }}
            >
            </div>
            <div className='estiloDivEstaticoFotoReduccion'>
                <div className='contenedorDivEstatico'>
                    <div className='divEstaticoIzquierda'>
                        <div className='imagenDivEstaticoIzquierda' style={{
                            opacity:
                                windowWidth < 1024 ? "1" :
                                   
                                        `${estaticoVisible ? "1" : "0"}`
                        }}>

                        </div>
                        <div className='tituloDivEstaticoIzquierda'>
                            <h2>Encuéntranos creando activos
                            </h2></div>
                    </div>
                    <div className='divEstaticoDerecha'>
                        <div className='contenedorDivEstaticoDerecha' ref={secondDivRef} style={{
                            opacity: isVisible ? 1 : 0,

                        }}
                        >
                            <div className="tituloDescripcionDivEstaticoDerecha">
                                <div className='contenedortituloDescripcionDivEstaticoDerecha'>
                                    <div className='tituloDivEstaticoDerecha'>
                                        <h2>Nuestra Cultura</h2>
                                    </div>
                                    <div className='descripcionDivEstaticoDerecha'>
                                        <p>Combinamos la tecnología y el ingenio humano para resolver retos a nivel nacional. Cuando trabajas con nosotros, las posibilidades son infinitas.
                                        </p>
                                    </div>
                                    <div className='expandirDivEstaticoDerecha'>
                                        <a href='https://partners.landersteams.com/nosotros/' className='expandirFotoReduccion'>
                                            <p className='tituloBotonExpandirFotoReduccion'>
                                                Expandir</p>
                                            <div className='iconoBotonExpandirFotoReduccion'>
                                                <img src={iconoFlechaExpandir}
                                                style={{
                                                    width:"100%",
                                                    height: "100%"
                                                }}
                                                ></img>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MiComponente;